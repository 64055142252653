import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import { FaMapMarker as IconMapMarker } from 'react-icons/fa';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

import { PrimaryButton } from './Button';

const Container = styled.div`
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
`;

const Title = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const TagLine = styled.p`
    color: ${({ theme }) => theme.blue};
    text-align: center;
`;
const Text = styled.p`
    font-size: 0.9rem;
    max-width: 1024px;
    text-align: center;
`;

const OfficesContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
`;

const OfficesTitle = styled.div`
    flex-grow: 2;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 600px) {
        flex-basis: 100%;
    }
`;

const Office = styled.div`
    &:after {
        margin: 0 0.5rem;
        content: '|';
    }
    &:last-child:after {
        content: none;
    }
`;

const StyledIconMapMarker = styled(IconMapMarker)`
    color: ${({ theme }) => theme.text};
    margin: 0 0.5rem;
`;

const TeamContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 900px;
`;

const Member = styled.a`
    color: ${({ theme }) => theme.text};
    margin: 0 2rem 3rem;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    img {
        transition: box-shadow 0.3s ease-in-out;
    }

    &:hover {
        color: ${({ theme }) => theme.blue};

        img {
            box-shadow: 0px 0px 0px 5px ${({ theme }) => theme.blue};
        }
    }

    @media (max-width: 600px) {
        margin: 0 1rem 1rem;
    }
`;

const Scene = styled.div`
    position: relative;
    vertical-align: bottom;
    perspective: 600px;

    height: ${({ size }) => size} !important;
    width: ${({ size }) => size} !important;

    @media (max-width: 600px) {
        height: 2rem;
        width: 115px;
    }
`;

const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
    transition: transform 1s;
    transition-delay: ${({ delay }) => delay}ms !important;
    transition-duration: ${({ duration }) => duration}ms;
    transform-style: preserve-3d;
    position: relative;

    > img {
        position: absolute;
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
        backface-visibility: hidden;
        transform: rotateX(0deg);

        &.alternative {
            transform: rotateY(180deg);
        }
    }

    .team-container:hover & {
        transform: rotateY(180deg);
    }
`;

const MemberImage = styled.img`
    border-radius: 50%;

    @media (max-width: 600px) {
        height: 4rem;
        width: 4rem;
    }
`;

const MemberName = styled.div`
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 0.9rem;
    }
`;

const JoinUsButton = PrimaryButton.withComponent(Link);

export const Team = ({ team, imageSize }) => {
    return (
        <TeamContainer className="team-container">
            {Object.keys(team).map(member => {
                const picture = team[member]['picture'];
                const alternativePicture =
                    team[member]['picture_sp'] || picture;

                return (
                    <Member key={member} href={team[member].link}>
                        <LazyLoad height={100} once offset={100}>
                            <Scene size={imageSize}>
                                <ImageContainer
                                    className={team[member.name]}
                                    delay={
                                        team[member].name === 'kevin'
                                            ? 2600
                                            : Math.floor(Math.random() * 400) +
                                              120
                                    }
                                    duration={
                                        Math.floor(Math.random() * 1200) + 600
                                    }
                                >
                                    <MemberImage
                                        role="presentation"
                                        className="default"
                                        src={picture}
                                    />
                                    <MemberImage
                                        role="presentation"
                                        className="alternative"
                                        src={alternativePicture}
                                    />
                                </ImageContainer>
                            </Scene>
                        </LazyLoad>
                        <MemberName>
                            {team[member].shortname || member}
                        </MemberName>
                    </Member>
                );
            })}
        </TeamContainer>
    );
};

Team.propTypes = {
    team: PropTypes.array.isRequired,
    imageSize: PropTypes.string,
};

Team.defaultProps = {
    imageSize: '6rem',
};

const Makers = ({ t, team, locale }) => (
    <Container>
        <Title>{t('wearemaker.title')}</Title>
        <TagLine>{t('wearemaker.tagline')}</TagLine>
        <Text>{t('wearemaker.text')}</Text>
        <OfficesContainer>
            <OfficesTitle>{t('wearemaker.offices')}</OfficesTitle>
            <StyledIconMapMarker />
            <Office>Nancy</Office>
            <Office>Dijon</Office>
            <Office>Lyon</Office>
            <Office>Strasbourg</Office>
        </OfficesContainer>
        <Team team={team} />
        <JoinUsButton to={`/${locale}/jobs`}>
            {t('wearemaker.joinus')}
        </JoinUsButton>
    </Container>
);

Makers.propTypes = {
    t: PropTypes.func.isRequired,
    team: PropTypes.array.isRequired,
    locale: PropTypes.string,
};

export default translate()(Makers);
