import React, { Fragment } from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import Helmet from 'react-helmet';

import { PrimaryLink } from './Button';
import { Team } from './Makers';
import AppBar from './AppBar';

import Footer from './Footer';
import MaxWidthContainer from './MaxWidthContainer';
import TopBar from './TopBar';
import CardBase from './Card';
import BaseViewMore from './ViewMore';

import Image from './Image/Image';
import WithBackgroundImage from './Image/WithBackgroundImage';
import HeaderImage from './HeaderImage';
import defaultTheme from './theme';

/* eslint-disable import/no-named-as-default-member */
const {
    Card: BaseCard,
    CardTitle: BaseCardTitle,
    CardImage,
    CardContent: BaseCardContent,
} = CardBase;
/*  eslint-enable import/no-named-as-default-member */

import {
    Creativity,
    Puzzle,
    KnowledgeSharing,
    Kindness,
    Cookies,
    Learning,
    Conferences,
    Letter,
    Conversation,
    GroupOfPeople,
    Achievement,
    Cogs,
    OilPipette,
    Laptop,
    OpenSource,
    Salary,
    Mentorship,
    HomeOffice,
} from './Icons';

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray}
    display: flex;
    flex-direction: column;
`;

const PresentationContainer = styled.div`
    padding: 1.875rem;
    background-color: white;
    max-width: 720px;
    margin: 0 auto 2rem auto;
    border-bottom: solid 1px ${({ theme }) => theme.shadowColor};
    @media (max-width: 600px) {
        position: static;
        width: auto;
        height: auto;
        padding: 1em;
    }
`;

const TextContainer = styled.div`
    margin-top: 0;
    padding: 0.625rem;
    > p:first-child {
        margin-top: 0;
    }
`;

const SubTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.headlinesColor};

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const TeamContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
    padding-top: 3rem;
    max-width: 1024px;
    margin: auto;
`;

const Text = styled.div`
    max-width: 720px;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
`;

const CardText = styled(Text)`
    margin-bottom: inherit;
`;

const GridContainer = styled(MaxWidthContainer)`
    display: flex;
    justify-content: center;
    margin: 0 0 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        flex-basis: ${({ basis }) => basis || 'auto'}%;
    }
`;

const Card = styled(BaseCard)`
    background-color: ${({ theme, opaque }) =>
        opaque ? theme.white : 'transparent'};
    ${({ theme, opaque }) =>
        opaque ? `border-bottom: 1px solid ${theme.shadowColor}` : null};
    margin: 0 0.5rem 0.5rem;
    max-width: 200px;
`;

const CardTitle = styled(BaseCardTitle)`
    font-size: 0.9rem;
    text-align: center;
    line-height: 1rem;
    margin: 0.5rem 0;
`;

const CardContent = styled(BaseCardContent)`
    text-align: center;
`;

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
`;

const GalleryImage = styled(WithBackgroundImage)`
    min-height: 20rem;
    margin: 1%;
    flew-grow: 1;

    &:nth-child(1) {
        margin-left: 0;
        min-width: 400px;
        width: 65%;
    }
    &:nth-child(2) {
        margin-right: 0;
        min-width: 200px;
        width: 33%;
    }

    &:nth-child(3) {
        margin-left: 0;
        min-width: 200px;
        width: 33%;
    }
    &:nth-child(4) {
        margin-right: 0;
        min-width: 400px;
        width: 65%;
    }
    &:nth-child(5) {
        margin-left: 0;
        width: 75%;
    }
    &:nth-child(6) {
        margin-right: 0;
        width: 23%;
    }

    @media (max-width: 650px) {
        &:nth-child(1) {
            margin-right: auto;
            min-width: auto;
            width: 100%;
        }
        &:nth-child(2) {
            width: 49%;
            margin-right: auto;
            margin-left: 0;
            min-width: auto;
        }
        &:nth-child(3) {
            width: 49%;
            margin-left: auto;
            margin-right: 0;
            min-width: auto;
        }
        &:nth-child(4) {
            margin-left: 0;
            margin-right: auto;
            min-width: auto;
            width: 100%;
        }
    }
`;

const ProcessContainer = styled.div`
    position: relative;
`;

const Process = styled(BaseCard)`
    margin: 0.5rem 0 0.5rem;
    margin-${({ side }) => (side === 'right' ? 'left' : 'right')}: 55%;
    border-bottom: solid 1px ${({ theme }) => theme.shadowColor};
    position: relative;

    @media (max-width: 650px) {
        margin-${({ side }) => (side === 'right' ? 'left' : 'right')}: 5%;
    }

    ::after {
        content: '';
    }
`;

const ProcessContent = styled.div`
    display: flex;
    flex-direction: row;
`;

const ProcessTimeline = styled.div`
    position: absolute;
    left: 50%;
    top: 2px;
    bottom: 2px;
    width: 1px;
    transform: translate(-50%);
    border: 5px solid #f2f2f2;
    margin-top: 5rem;

    ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #535249;
    }

    @media (max-width: 650px) {
        display: none;
    }
`;

const ProcessIcon = styled.div`
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    ${({ side }) => (side === 'right' ? 'left' : 'right')}: -80px;
    background-color: #f2f2f2;

    @media (max-width: 650px) {
        display: none;
    }
`;

const ProcessDescription = styled.div`
    order: ${({ side }) => (side === 'right' ? 2 : 1)};
    margin: 0.5em;
`;

const ProcessLink = styled.div`
    margin: 0 0.5em;
`;

const TeamPhoto = styled(WithBackgroundImage)`
    height: 400px;
    & > img {
        object-position: 30% center !important;
        font-family: 'object-position: 30% center !important;'; // needed for IE9+ polyfill
    }
`;

const HorizontalSeparator = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    z-index: -1;
    padding: 1em 1em;
    min-height: 1.5rem;

    p {
        position: relative;
        margin: 0;
        padding: 0 1rem;
        text-transform: uppercase;
        background-color: #f2f2f2;
        width: 100%;

        @media (min-width: 651px) {
            position: absolute;
            left: calc(55% - 1rem);
            width: auto;
        }
    }

    &:before {
        content: '';
        width: 100%;
        border-top: solid 1px ${({ theme }) => theme.shadowColor};
        margin-top: 0.75em;

        @media (max-width: 650px) {
            display: none;
        }
    }
`;

const ViewMore = props => <BaseViewMore {...props} color={defaultTheme.blue} />;

const values = [
    { name: 'creativity', icon: <Creativity /> },
    { name: 'pragmatism', icon: <Puzzle /> },
    { name: 'knowledge-sharing', icon: <KnowledgeSharing /> },
    { name: 'kindness', icon: <Kindness /> },
    { name: 'craving', icon: <Cookies /> },
];

const perks = [
    { name: 'hackday', icon: <Learning /> },
    { name: 'conferences', icon: <Conferences /> },
    { name: 'laptop', icon: <Laptop /> },
    { name: 'open-source', icon: <OpenSource /> },
    { name: 'salary', icon: <Salary /> },
    { name: 'coaching', icon: <Mentorship /> },
    { name: 'homeOffice', icon: <HomeOffice /> },
];

const process = [
    { name: 'contact', icon: <Letter /> },
    { name: 'technical-review', icon: <Conversation /> },
    { name: 'team-fit-review', icon: <GroupOfPeople /> },
    {
        name: 'integration',
        icon: <Achievement />,
        link: '/blog/2018/09/05/agile-integration.html',
    },
];

const jobs = [
    {
        name: 'developer',
        icon: <Cogs />,
        link: locale => `/${locale}/jobs/developer`,
    },
    {
        name: 'facilitator',
        icon: <OilPipette />,
        link: locale => `/${locale}/jobs/facilitator`,
    },
];

const Jobs = ({
    headerImage,
    galleryImages,
    projectImages,
    footerImage,
    locale,
    t,
    team,
}) => {
    const projects = [
        {
            name: 'arte',
            icon: <Image src={projectImages['arte']} />,
            link: '/blog/2021/01/07/migration-continue-chez-arte-pourquoi.html',
        },
        {
            name: 'amnesty',
            icon: <Image src={projectImages['amnesty']} />,
            link: '/blog/2020/01/16/amnesty-build-measure-learn.html',
        },
        {
            name: 'react-admin',
            icon: <Image src={projectImages['react-admin']} />,
            link: 'https://github.com/marmelab/react-admin',
        },
    ];

    return (
        <Container>
            <Helmet title={t('seo.jobTitle')}>
                <html lang={locale} />
                <meta name="description" content={t('seo.jobDescription')} />
            </Helmet>
            <TopBar white role="presentation" />
            <header>
                <AppBar />
                <HeaderImage image={headerImage} />
            </header>
            <div role="main">
                <MaxWidthContainer width={1024}>
                    <SubTitle
                        dangerouslySetInnerHTML={{
                            __html: t('jobs.why'),
                        }}
                    />
                    <PresentationContainer>
                        <TextContainer
                            dangerouslySetInnerHTML={{
                                __html: t('jobs.presentation'),
                            }}
                        />
                    </PresentationContainer>
                    <GridContainer>
                        {values.map(({ name, icon }) => (
                            <Card key={name}>
                                <CardImage>{icon}</CardImage>
                                <CardTitle>
                                    {t(`jobs.values.${name}.name`)}
                                </CardTitle>
                            </Card>
                        ))}
                    </GridContainer>
                    <SubTitle>{t('jobs.team.life')}</SubTitle>
                    <ImageContainer>
                        <GalleryImage image={galleryImages['hackday']} />
                        <GalleryImage image={galleryImages['lego']} />
                        <GalleryImage image={galleryImages['teapot']} />
                        <GalleryImage image={galleryImages['team-building']} />
                        <GalleryImage
                            image={galleryImages['cooking']}
                            minWidth="200px"
                            width="75%"
                        />
                        <GalleryImage
                            image={galleryImages['origami']}
                            minWidth="120px"
                            width="21%"
                        />
                    </ImageContainer>
                    <Text>{t('jobs.team.content')}</Text>
                    <TeamContainer>
                        <Team team={team} />
                    </TeamContainer>
                    <SubTitle>{t('jobs.projects.title')}</SubTitle>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: t('jobs.projects.intro'),
                        }}
                    />
                    <GridContainer>
                        {projects.map(({ name, icon, link }) => (
                            <Card key={name} opaque>
                                <CardImage>{icon}</CardImage>
                                <CardTitle>
                                    {t(`jobs.projects.${name}.name`)}
                                </CardTitle>
                                <CardContent>
                                    {t(`jobs.projects.${name}.description`)}
                                </CardContent>
                                <ViewMore
                                    label={t(`jobs.projects.link`)}
                                    href={link}
                                    centered
                                />
                            </Card>
                        ))}
                    </GridContainer>
                    <SubTitle>{t('jobs.perks.title')}</SubTitle>
                    <GridContainer>
                        {perks.map(({ name, icon }) => (
                            <Card key={name}>
                                <CardImage>{icon}</CardImage>
                                <CardTitle>
                                    {t(`jobs.perks.${name}.name`)}
                                </CardTitle>
                                <CardContent>
                                    {t(`jobs.perks.${name}.description`)}
                                </CardContent>
                            </Card>
                        ))}
                    </GridContainer>
                    <SubTitle>{t('jobs.process.title')}</SubTitle>
                    <ProcessContainer>
                        <ProcessTimeline />
                        {process.map(({ name, icon, link }, index) => {
                            const side = index % 2 !== 0 ? 'right' : 'left';
                            const isLastStep = index === process.length - 1;
                            return (
                                <Fragment key={name}>
                                    {isLastStep && (
                                        <HorizontalSeparator>
                                            <Text>
                                                {t(`jobs.process.contract`)}
                                            </Text>
                                        </HorizontalSeparator>
                                    )}
                                    <Process side={side}>
                                        <ProcessContent side={side}>
                                            <ProcessIcon side={side}>
                                                {icon}
                                            </ProcessIcon>
                                            <ProcessDescription side={side}>
                                                <CardTitle
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {t(
                                                        `jobs.process.${name}.name`,
                                                    )}
                                                </CardTitle>
                                                <CardText
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {t(
                                                        `jobs.process.${name}.description`,
                                                    )}
                                                </CardText>
                                            </ProcessDescription>
                                        </ProcessContent>
                                        {link && (
                                            <ProcessLink>
                                                <ViewMore
                                                    label={t(
                                                        `jobs.process.${name}.link`,
                                                    )}
                                                    href={link}
                                                />
                                            </ProcessLink>
                                        )}
                                    </Process>
                                </Fragment>
                            );
                        })}
                    </ProcessContainer>
                    <SubTitle
                        dangerouslySetInnerHTML={{
                            __html: t('jobs.apply.title'),
                        }}
                    />
                    <Text>{t('jobs.apply.jobs')}</Text>
                    <GridContainer basis={50}>
                        {jobs.map(({ name, icon, link }) => (
                            <Card key={name} opaque>
                                <CardImage>{icon}</CardImage>
                                <CardTitle>
                                    {t(`jobs.apply.${name}.name`)}
                                </CardTitle>
                                <ViewMore
                                    label={t(`jobs.apply.link`)}
                                    href={link(locale)}
                                    centered
                                />
                            </Card>
                        ))}
                    </GridContainer>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: t('jobs.apply.other'),
                        }}
                        style={{ marginBottom: '0.5em' }}
                    />
                    <PrimaryLink
                        href="mailto:contact@marmelab.com?subject=Interested in a position at marmelab"
                        style={{
                            textAlign: 'center',
                            margin: '0.5rem auto 3em',
                        }}
                    >
                        {t('aboutus.contact_us')}
                    </PrimaryLink>
                </MaxWidthContainer>
                <TeamPhoto
                    image={footerImage}
                    role="presentation"
                    alt="team photo"
                />
            </div>
            <Footer />
        </Container>
    );
};

Jobs.propTypes = {
    locale: PropTypes.string,
    t: PropTypes.func,
    showcases: PropTypes.object,
    headerImage: PropTypes.object,
    galleryImages: PropTypes.object,
    projectImages: PropTypes.object,
    footerImage: PropTypes.object,
    team: PropTypes.object,
};

export default compose(
    mapProps(({ data, locale }) => {
        const team = data.team.edges.reduce(
            (acc, { node: { frontmatter } }) => ({
                ...acc,
                [frontmatter.name]: frontmatter,
            }),
            {},
        );

        return {
            locale,
            team,
            headerImage: data.headerImage,
            galleryImages: data.galleryImages.edges.reduce((acc, { node }) => {
                acc[node.name] = node;
                return acc;
            }, {}),
            projectImages: data.projectImages.edges.reduce((acc, { node }) => {
                acc[node.name] = node;
                return acc;
            }, {}),
            footerImage: data.footerImage,
        };
    }),
    translate(),
)(Jobs);
