import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../components/i18n/withSetLocale';

import Layout from '../../components/layout';
import JobsPage from '../../components/JobsPage';

const Jobs = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <JobsPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Jobs.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Jobs);

export const query = graphql`
    query JobsQuery {
        headerImage: file(relativePath: { eq: "jobs/staff.jpg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        galleryImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                absolutePath: { glob: "**/jobs/gallery/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 650, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

        projectImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                absolutePath: { glob: "**/jobs/projects/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fixed(width: 60, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }

        footerImage: file(relativePath: { eq: "jobs/team.jpg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        team: allMdx(
            sort: { fields: fileAbsolutePath }
            filter: {
                fileRelativePath: { glob: "**/team/**" }
                frontmatter: { retired: { ne: true } }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        name
                        fullname
                        shortname
                        link
                        picture
                        picture_sp
                        retired
                    }
                }
            }
        }
    }
`;
